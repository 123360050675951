import { styled, Typography } from "@mui/material";
import { mobileDesign, mobileScreen } from "./breakpoints";

export const MainTitle = ({ children, shadow, color, ...otherProps }) => {
  const StyledText = styled(Typography)`
    font-family: "F37 Judge", sans-serif;
    font-size: 6.3rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: 2.2px;
    color: ${color};
    text-transform: uppercase;

    text-shadow: ${shadow ? "0 9px 9px rgba(15, 23, 68, 0.17)" : ""};

    b {
      color: #53baff;
    }

    ${mobileDesign} {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    ${mobileScreen} {
      font-size: 4.7rem;
    }
  `;

  return <StyledText {...otherProps}>{children}</StyledText>;
};

export const SecondaryTitle = ({ children, ...otherProps }) => {
  const StyledTypography = styled(Typography)`
    font-family: "F37 Judge", sans-serif;
    font-size: 4.6rem;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: 1.61px;
    color: #010a10;

    b {
      font-weight: bold;
      color: #53baff;
    }
    ${mobileDesign} {
      text-align: center;
    }

    ${mobileScreen} {
      font-size: 3.4rem;
    }
  `;

  return (
    <StyledTypography variant={"h2"} {...otherProps}>
      {children}
    </StyledTypography>
  );
};

export const Paragraph = styled(Typography)`
  font-family: "Hellix", sans-serif;
  font-size: 2rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: -0.21px;
  color: #3d5f78;

  b {
    font-weight: 600;
  }
  ${mobileDesign} {
    text-align: center;
  }

  ${mobileScreen} {
    font-size: 1.6rem;
  }
`;
