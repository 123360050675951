import { FlexBox, ImgBox, SectionContainer } from "../../styles/layout";
import {NoteContainer, StepItem, StepNumber, StepsContainer, StepsNote} from "./Steps.styles";
import { Paragraph, SecondaryTitle } from "../../styles/typography";
import Grid2 from "@mui/material/Unstable_Grid2";
import { List } from "@mui/material";
import { stepsList } from "./Steps.data";
import { MainButton } from "../../styles/buttons";
import { AlertIcon, CodeIcon } from "../../assets/icons/buttonIcons";
import stepsImg from "./img/steps.webp";
import {PopupButton} from "@typeform/embed-react";

const Steps = () => {
  return (
    <SectionContainer pb={{xs: '10rem', md: "15rem"}}>
      <StepsContainer>
        <Grid2 container mb={"5rem"}>
          <Grid2 md={6}>
            <SecondaryTitle sx={{ color: "#fff" }} mb={"5rem"}>
              <b> Interested? </b> It just takes 3 steps
            </SecondaryTitle>
            <List sx={{ mb: "6.6rem" }}>
              {stepsList.map((el, i) => (
                <StepItem key={el}>
                  <StepNumber>
                    <span>{i + 1}</span>
                  </StepNumber>
                  <Paragraph>{el}</Paragraph>
                </StepItem>
              ))}
            </List>
            <MainButton startIcon={<CodeIcon />} component={PopupButton} id={"WRwT04gx"}>Install pixel</MainButton>
          </Grid2>
          <Grid2 md={6} className={'desktopOnly'}>
            <ImgBox>
              <img src={stepsImg} alt="rocket launch" />
            </ImgBox>
          </Grid2>
        </Grid2>
        <NoteContainer>
          <AlertIcon />
          <StepsNote ml={".8rem"}>Note - If you keep the pixel installed, your rewards will keep pile up, even after the airdrop</StepsNote>
        </NoteContainer>
      </StepsContainer>
    </SectionContainer>
  );
};

export default Steps;
