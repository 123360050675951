import { useCallback, useEffect, useRef, useState } from "react";

const AVG_TRAFFIC = 2500000;
const REWARDS = 86400;

export const useCalculator = () => {
  const [mode, setMode] = useState("traffic");
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [input, setInput] = useState("");

  const onInputChange = (e) => {
    setInput(e.target.value);
  };

  const toggleMode = async () => {
    if (mode === "traffic") {
      setTimeout(() => {
        setMode("reward");
      }, [2000]);
      let count = 0;
      const interval = setInterval(() => {
        if (count === 9) {
          setLoadingPercent(0);
          clearInterval(interval);
        } else {
          setLoadingPercent((prev) => prev + 10);
          count++;
        }
      }, [200]);
    } else {
      setMode("traffic");
      setLoadingPercent(0);
    }
  };

  const CONDITIONS = {
    isTraffic: mode === "traffic",
    isLoading: loadingPercent > 0
  }

  const renderContent = (condition, a, b) => {
    if (CONDITIONS[condition]) {
      return a;
    } else {
      return b;
    }
  };

  const getRewards = useCallback(() => {
    return Math.round((input / AVG_TRAFFIC) * REWARDS);
  }, [input]);

  const isRewardMode = mode === 'reward'

  return { toggleMode, renderContent, onInputChange, getRewards, input, onInputChange, loadingPercent, isRewardMode };
};
