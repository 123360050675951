import {Box} from "@mui/material";
import {Logo} from "./img/logo";
import {HeaderContainer} from "./Header.styles";

const Header = () => {
  return <HeaderContainer>
    <Logo/>
  </HeaderContainer>;
};

export default Header;
