import { RelativeBox, SectionContainer } from "../../styles/layout";
import { EcosystemBackground, EcosystemDataItem } from "./Ecosystem.styles";
import { MainTitle, Paragraph } from "../../styles/typography";
import { ecosystemList } from "./Ecosystem.data";
import { Box, Stack } from "@mui/material";

const Ecosystem = () => {
  return (
    <RelativeBox>
      <EcosystemBackground />
      <SectionContainer p={{xs: '10rem 0', md: "16rem 0"}}>
        <MainTitle color={"#0f3987"} mb={"8.8rem"}>
          <b> A data ecosystem </b> that’s <br /> better for all
        </MainTitle>
        <Stack spacing={"8.8rem"}>
          {ecosystemList.map((el) => (
            <EcosystemDataItem key={el.text}>
              <Box>{el.icon}</Box>
              <Paragraph>{el.text}</Paragraph>
            </EcosystemDataItem>
          ))}
        </Stack>
      </SectionContainer>
    </RelativeBox>
  );
};

export default Ecosystem;
