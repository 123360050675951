import { AllRights, FooterContainer, LogoFooterBox, SocialMediaContainer, SocialMediaList, SubContainer } from "./Footer.styles";
// import { SocialMedia } from "../../socialMedia/SocialMedia";
import { Logo } from "../header/img/logo";
import { footerSocialMedia } from "./Footer.data";
import { Typography } from "@mui/material";
import { FlexBox } from "../../styles/layout";

export const Footer = () => {
  return (
    <FooterContainer>
      {/*{screen1024 && <SocialMedia />}*/}
      <SubContainer>
        <LogoFooterBox>
          <Logo />
        </LogoFooterBox>
        {/*<SocialMedia />*/}
        <SocialMediaList>
          {footerSocialMedia.map((sm) => (
            <a key={sm.title} href={sm.link} target={sm.title}>
              <SocialMediaContainer>
                {sm.icon}
                <Typography>{sm.title}</Typography>
              </SocialMediaContainer>
            </a>
          ))}
        </SocialMediaList>
        <AllRights>{"All rights reserved © 2023 Web3m"}</AllRights>
      </SubContainer>
    </FooterContainer>
  );
};

export default Footer;
