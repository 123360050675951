import {FlexBox, FlexResponsive, SectionBackground} from "../../styles/layout";
import { styled } from "@mui/material";
import {mobileDesign} from "../../styles/breakpoints";

export const EcosystemBackground = styled(SectionBackground)`
  background-color: #fafafa;
`;

export const EcosystemDataItem = styled(FlexResponsive)`
  max-width: 102.4rem;

  > div {
    border-radius: 1.4rem;
    box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    width: 20rem;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 4rem;
    
    ${mobileDesign}{
      margin: 0 auto 5rem;
    }
  }
  
  p{
    ${mobileDesign}{
      min-width: 28rem;
      width: 80%;
      margin: 0 auto ;
    }
  }
`;
