import { SectionContainer } from "../../styles/layout";
import { SecondaryTitle } from "../../styles/typography";
import { MainButton } from "../../styles/buttons";
import { CodeIcon } from "../../assets/icons/buttonIcons";
import { PopupButton } from "@typeform/embed-react";

const Cta = () => {
  return (
    <SectionContainer p={{ xs: "10rem 0", md: "10rem 0 15rem" }}>
      <SecondaryTitle mb={{ xs: "3.2rem", md: "4.5rem" }}>
        <b style={{ color: "#000" }}> Want in? </b>start here
      </SecondaryTitle>
      <MainButton startIcon={<CodeIcon />} component={PopupButton} id={"WRwT04gx"}>
        Install pixel
      </MainButton>
    </SectionContainer>
  );
};

export default Cta;
