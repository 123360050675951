export function DiscordIcon(props) {
  return (
    <svg width="2.6rem" height="1.9rem" viewBox="0 0 26 19" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.328 7.993c-1.221 0-2.185 1.072-2.185 2.379s.985 2.378 2.185 2.378c1.222 0 2.186-1.071 2.186-2.378 0-1.307-.964-2.379-2.186-2.379m-7.821 0c-1.221 0-2.186 1.072-2.186 2.379s.986 2.378 2.186 2.378c1.221 0 2.186-1.071 2.186-2.378.021-1.307-.965-2.379-2.186-2.379M18.47 18s-.771-.921-1.414-1.736c2.807-.792 3.878-2.55 3.878-2.55-.878.579-1.714.986-2.464 1.265-1.071.45-2.1.75-3.107.921a15.01 15.01 0 01-5.55-.021 17.973 17.973 0 01-3.15-.922A12.547 12.547 0 015.1 14.23c-.064-.043-.129-.065-.193-.107-.043-.022-.064-.043-.086-.065-.385-.214-.6-.364-.6-.364s1.029 1.714 3.75 2.529C7.328 17.036 6.536 18 6.536 18 1.8 17.85 0 14.743 0 14.743 0 7.843 3.086 2.25 3.086 2.25 6.17-.064 9.106 0 9.106 0l.215.258C5.464 1.372 3.686 3.065 3.686 3.065s.471-.257 1.264-.622c2.293-1.007 4.114-1.286 4.864-1.35.129-.021.236-.043.364-.043a18.13 18.13 0 014.329-.042c2.035.235 4.221.835 6.45 2.057 0 0-1.693-1.608-5.336-2.722l.3-.343s2.936-.064 6.021 2.25c0 0 3.086 5.593 3.086 12.493 0 0-1.821 3.107-6.557 3.257"
        transform="translate(-889 -5320) translate(0 5287.768) translate(889.428 32.846)"
        fill="#9DA1AA"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
        opacity={0.721787226}
      />
    </svg>
  );
}

export function TwitterIcon(props) {
  return (
    <svg
      width="2.4rem"
      height="2rem"
      viewBox="0 0 24 20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path id="a" d="M0 0L23.2564006 0 23.2564006 18.9 0 18.9z" />
      </defs>
      <g
        transform="translate(-1017 -5321) translate(0 5287.768) translate(1017.362 33.596)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        opacity={0.721787226}
      >
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M23.256 2.237a9.538 9.538 0 01-2.74.752 4.786 4.786 0 002.098-2.64 9.554 9.554 0 01-3.03 1.158 4.771 4.771 0 00-8.13 4.352A13.545 13.545 0 011.619.873a4.749 4.749 0 00-.646 2.399 4.77 4.77 0 002.123 3.971 4.752 4.752 0 01-2.161-.596v.06a4.774 4.774 0 003.827 4.678 4.781 4.781 0 01-2.155.082 4.776 4.776 0 004.457 3.313 9.573 9.573 0 01-5.926 2.043c-.385 0-.765-.023-1.138-.067A13.504 13.504 0 007.314 18.9c8.776 0 13.575-7.27 13.575-13.576 0-.206-.004-.412-.013-.617a9.692 9.692 0 002.38-2.47"
          fill="#9DA1AA"
          mask="url(#b)"
        />
      </g>
    </svg>
  );
}

export function LinkedinIcon(props) {
  return (
    <svg width="2rem" height="2rem" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#9DA1AA" stroke="none" strokeWidth={1} fillRule="evenodd" opacity={0.721787226}>
        <path
          d="M2.276 0a2.276 2.276 0 11-.003 4.551A2.276 2.276 0 012.276 0zM.311 18.9H4.24V6.276H.311V18.9zM6.7 6.276h3.761V8h.054c.523-.992 1.804-2.039 3.713-2.039 3.972 0 4.706 2.614 4.706 6.014V18.9h-3.923v-6.14c0-1.463-.025-3.347-2.039-3.347-2.041 0-2.353 1.596-2.353 3.243V18.9H6.7V6.276z"
          transform="translate(-1139 -5318) translate(0 5287.768) translate(1139.524 30.446)"
        />
      </g>
    </svg>
  );
}

export function TiktokIcon(props) {
  return (
    <svg width="1.8rem" height="2rem" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.002.542C11.994.407 11.997 0 11.997 0h-3.19v12.952a2.806 2.806 0 11-2.042-2.7V7.046A6 6 0 1012.002 13V6.075a5.39 5.39 0 004.387 1.548V4.625c-2.278-.102-4.257-1.839-4.387-4.083"
        transform="translate(-1261 -5319) translate(0 5287.768) translate(1261.686 31.346)"
        fill="#9DA1AA"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
        opacity={0.721787226}
      />
    </svg>
  );
}
