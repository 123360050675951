import { Box, styled } from "@mui/material";
import {mobileDesign} from "./breakpoints";

export const SectionContainer = styled(Box)`
  position: relative;
  width: 90%;
  max-width: 144rem;
  margin: 0 auto;
  z-index: 1;
`;

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
`;

export const FlexResponsive = styled(FlexBox)`

    ${mobileDesign}{
      flex-direction: column;
    }
`

export const RelativeBox = styled(Box)`
  position: relative;
`;

export const SectionBackground = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const ImgBox = styled(Box)`
  img {
    width: 100%;
  }
`;
