import { ImgBox, RelativeBox, SectionContainer } from "../../styles/layout";
import {TitleBackground, TitleImage} from "./Title.styles";
import Grid2 from "@mui/material/Unstable_Grid2";
import { MainTitle } from "../../styles/typography";
import titleImg from "./img/title.webp";

const Title = () => {
  return (
    <RelativeBox>
      <TitleBackground />
      <SectionContainer pt={"10rem"}>
        <Grid2 container>
          <Grid2 xs={12} md={6}>
            <MainTitle color={"#fff"} shadow m={'5rem 0 10rem'}>
              Install the pixel <br />
              <b>Get rewarded !</b>
            </MainTitle>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <TitleImage>
              <img src={titleImg} alt={"laptop"} />
            </TitleImage>
          </Grid2>
        </Grid2>
      </SectionContainer>
    </RelativeBox>
  );
};

export default Title;
