import {styled} from "@mui/material";
import {ImgBox, SectionBackground} from "../../styles/layout";
import {mobileDesign, mobileScreen} from "../../styles/breakpoints";


export const TitleBackground = styled(SectionBackground)`
  background-image: linear-gradient(277deg, #0f143f, #0e75fc);
`

export const TitleImage = styled(ImgBox)`
  transform-origin: left;
  
    width: 50%;
  transform: scale(2) translateY(25%);
  
  ${mobileDesign}{
    transform: scale(1.8) translateY(5%);
    max-width: 30rem;
    margin: 0 auto;
    transform-origin: center;
  }
  
  ${mobileScreen}{
    transform: scale(2) translateY(5%);

  }
`