import {EcosystemIcon1, EcosystemIcon2, EcosystemIcon3} from "./Ecosystem.icons";

export const ecosystemList = [
  {
    icon: EcosystemIcon1(),
    text: "Web3 sites install a pixel into their website that sends anonymous analytics data to an advertising ecosystem. For sharing their data, these websites literally get paid by advertisers.",
  },  {
    icon: EcosystemIcon2(),
    text: "Web3 users have their information anonymously tracked—and they can delete that data at any time. Users who keep their data in the ecosystem are paid every time an ad uses their specific data points.",
  },  {
    icon: EcosystemIcon3(),
    text: "Advertisers can access better data than what’s available in web2, from people and site owners who are compensated for access to their data.",
  },
];
