import { ImgBox, SectionContainer } from "../../styles/layout";
import Grid2 from "@mui/material/Unstable_Grid2";
import { MainTitle, Paragraph, SecondaryTitle } from "../../styles/typography";
import visionImg from "./img/vision.webp";

const Vision = () => {
  return (
    <SectionContainer pb={{ xs: "10rem", md: "15rem" }}>
      <Grid2 container spacing={{ xs: 0, md: "10rem" }}>
        <Grid2 xs={12} md={7.5}>
          <MainTitle color={"#0f3987"} mb={"6rem"}>
            A new approach to data: <br /> <b> Our vision for web3</b>
          </MainTitle>
          <SecondaryTitle mb={"3.6rem"}>Let’s cut to the chase. Why do we want your data anyway?</SecondaryTitle>
          <Paragraph>
            Web2 blew an awesome opportunity. Instead of data being beneficial to all, it became a tool for harm—and a profit machine for
            the already wealthy and successful.
            <br /> <br />
            We’re building something different. Web3m will be a data ecosystem that benefits web3 users, websites, and advertisers in equal
            measure.
          </Paragraph>
        </Grid2>
        <Grid2 xs={12} md={4.5}>
          <ImgBox sx={{ width: { xs: "80%", md: "100%" }, margin: "3rem auto 0" }}>
            <img src={visionImg} alt={"finance"} />
          </ImgBox>
        </Grid2>
      </Grid2>
    </SectionContainer>
  );
};

export default Vision;
