import { FlexBox, FlexResponsive, SectionContainer } from "../../styles/layout";
import { MainTitle, Paragraph, SecondaryTitle } from "../../styles/typography";
import { Divider, FormControl, FormLabel, Stack, Typography } from "@mui/material";
import {
  CalculatorBackground,
  CalculatorContainer,
  CalculatorContent,
  CalculatorInput,
  LoaderContainer,
  ResultsBox,
  RewardsDisplay,
  RewardsValue,
  TrafficFormLabel,
} from "./Calculator.styles";
import {BulbIcon, CalcLoader, CalcMobileBackground, CalculatorIcon, SilhouetteIcon} from "./Calculator.icons";
import { MainButton } from "../../styles/buttons";
import { CodeIcon, CoinsIcon } from "../../assets/icons/buttonIcons";
import { useCalculator } from "./useCalculator";

const Calculator = () => {
  const { toggleMode, renderContent, input, onInputChange, getRewards, loadingPercent, isRewardMode } = useCalculator();

  return (
    <SectionContainer pb={{ xs: "5rem", md: "15rem" }}>
      <MainTitle color={"#0f3987"} mb={"3rem"}>
        Profit <b>calculator</b>{" "}
      </MainTitle>
      <Paragraph width={{ xs: "100%", md: "55%" }} mb={"6.5rem"}>
        Web3m improves through your data. The more data you supply, the more profits you can expect. Try our calculator for a profit
        estimate.
      </Paragraph>
      <Divider sx={{ mb: "6.5rem" }} className={"desktopOnly"} />

      <CalculatorContainer>
        <CalculatorBackground>
          <CalcMobileBackground/>
          <SecondaryTitle mb={"6rem"}>What is your estimated website traffic? (monthly)</SecondaryTitle>
          <CalculatorContent>
            <Stack spacing={{xs: '2rem', md: "3.4rem"}}>
              <FormControl sx={{ width: { xs: "100%", md: "auto" } }}>
                <TrafficFormLabel>Avg.</TrafficFormLabel>
                <CalculatorInput
                  type={"number"}
                  value={input}
                  onChange={onInputChange}
                  placeholder={"ENTER your WEBSITE traffic_"}
                  disabled={isRewardMode || loadingPercent}
                />
              </FormControl>
              ,
              <MainButton
                startIcon={renderContent("isTraffic", <BulbIcon />, null)}
                disabled={loadingPercent || !input}
                className={isRewardMode ? "blue" : ""}
                onClick={toggleMode}
              >
                {renderContent("isTraffic", "Calculate rewards", "reset")}
              </MainButton>
            </Stack>
            <ResultsBox>
              {renderContent(
                "isLoading",
                <FlexBox>
                  <LoaderContainer>
                    <CalcLoader percent={loadingPercent} />
                    <Typography>{loadingPercent}%</Typography>
                  </LoaderContainer>
                  <Typography>Calculating...</Typography>
                </FlexBox>,
                renderContent(
                  "isTraffic",
                  <Typography>Waiting for entry...</Typography>,
                  <RewardsValue>
                    <Typography variant={"h5"}>Avg. Reward</Typography>
                    <Typography>
                      <b>{getRewards()}</b> $WEBM
                    </Typography>
                  </RewardsValue>
                )
              )}
            </ResultsBox>
          </CalculatorContent>
        </CalculatorBackground>
        <CalculatorIcon className={'desktopOnly'} />
      </CalculatorContainer>
        <Divider className={"desktopOnly"} />
    </SectionContainer>
  );
};

export default Calculator;
