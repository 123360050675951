import "./App.css";
import Title from "./sections/title/Title";
import Community from "./sections/community/Community";
import Steps from "./sections/steps/Steps";
import Calculator from "./sections/calculator/Calculator";
import Vision from "./sections/vision/Vision";
import Ecosystem from "./sections/ecosystem/Ecosystem";
import Header from "./sections/header/Header";
import Footer from "./sections/footer/Footer";
import { CssBaseline } from "@mui/material";
import CTA from "./sections/cta/CTA";

function App() {
  return (
    <div>
      <Header />
      <Title />
      <Community />
      <Steps />
      <Calculator />
      <Vision />
      <Ecosystem />
      <CTA />
      <Footer />
      <CssBaseline />
    </div>
  );
}

export default App;
