import { Box, styled } from "@mui/material";
import { mobileDesign } from "../../styles/breakpoints";

export const HeaderContainer = styled("header")`
  padding: 4.3rem 5.2rem;
  width: 100%;
  position: absolute;
  z-index: 100;

  ${mobileDesign} {
    background-color: #fff;

    svg {
      display: block;
      margin: 0 auto;
      path {
        fill: #0e5ecf;
      }
    }
  }
`;
