import { Button, styled } from "@mui/material";
import {mobileDesign} from "./breakpoints";

export const MainButton = styled(Button)`
  background-color: #e7526a;
  border-radius: 0.72rem;
  height: 6.5rem;
  padding: 1.8rem 4rem;

  font-size: 2.16rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.24px;
  color: #fff;
  
  &:disabled{
    opacity: .35;
    color: #fff;

  }
  
  ${mobileDesign}{
    width: 100%;
  }

  &:hover {
    background-color: #e7526a;
  }

  &.blue {
    background-color: #46AEF4;
    border: 1px solid #fff;
  }
`;
