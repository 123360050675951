import { SectionContainer } from "../../styles/layout";
import { MainTitle, Paragraph, SecondaryTitle } from "../../styles/typography";
import { Typography } from "@mui/material";

const Community = () => {
  return (
    <SectionContainer p={{xs: '14rem 0 7rem', md: "14rem 0"}}>
      <MainTitle color={"#0f3987"} width={{ xs: "100%", md: "50%" }} maxWidth={"56rem"} mb={"6rem"}>
        Don’t miss <b> the community airdrop</b>
      </MainTitle>
      <SecondaryTitle maxWidth={"90rem"} mb={"3rem"} width={{ xs: "100%", md: "70%" }}>
        At Web3m, we want to set the foundation for data in web3. It’s a big task that we can’t do alone.
      </SecondaryTitle>

      <Paragraph sx={{ maxWidth: "80rem", width: { xs: "100%", md: "55%" } }}>
        That’s why we’re issuing an airdrop in <b> Q4 2023 </b> to everyone who installs the Web3m pixel to their website. It’s our way of
        thanking you for being part of the community - and helping make web3 better for users, website owners, and advertisers.
      </Paragraph>
    </SectionContainer>
  );
};

export default Community;
