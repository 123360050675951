import { Box, FormLabel, OutlinedInput, Stack, styled } from "@mui/material";
import { FlexBox } from "../../styles/layout";
import { mobileDesign, mobileScreen } from "../../styles/breakpoints";

export const CalculatorInput = styled(OutlinedInput)`
  //border: solid 1px #010a10;
  box-shadow: 0 2px 5px 0 #0688df;
  background-color: #fff;
  border-radius: 0.72rem;
  height: 6.5rem;
  //width: 20rem;

  &:focus,
  &:hover {
    border: none;

    fieldset {
      border: none;
    }
  }

  ${mobileDesign} {
    width: 100%;
    //margin: 0 0 5rem 0;
  }

  svg {
    height: 3.6rem;
    transform: translateX(-4rem) scale(4);
    z-index: 1;

    ${mobileDesign} {
      transform: translateX(-3rem) scale(4);
    }
  }

  input {
    padding: 1.3rem 2rem;
    font-family: "F37 Judge";
    font-size: 2.73rem;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 0.95px;
    color: #010a10;
    z-index: 1;
  }
`;

export const TrafficFormLabel = styled(FormLabel)`
  font-size: 1.43rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #fff;
  //margin-left: 5rem;
  margin-bottom: 0.5rem;
`;

export const CalculatorContainer = styled(FlexBox)`
  margin-bottom: 7rem;
  //position: relative;
  //overflow: hidden;

  > svg {
    margin-left: -3rem;
  }
`;
export const CalculatorBackground = styled(Box)`
  border-radius: 1.1rem;
  background-color: #53baff;
  padding: 6.6rem 6rem 9rem 7rem;

  ${mobileDesign} {
    .mobileBg {
      display: none;
    }
  }

  ${mobileScreen} {
    padding: 3rem 3rem 7rem;
    background-color: transparent;
    background-image: linear-gradient(to bottom, transparent 25%, #53baff 25%);

    .mobileBg {
      display: block;
      position: absolute;
      width: 106.5%;
      left: 0;
    }

    h2 {
      position: relative;
      z-index: 1;
      width: 60%;
      min-width: 30rem;
      margin: 15rem auto 5rem;
    }
  }

  h2 {
    color: #fff;
  }
`;

export const CalculatorContent = styled(FlexBox)`
  align-items: stretch;
  width: 100%;
  column-gap: 4.8rem;

  > div {
    flex: 1 1 50%;
  }

  ${mobileDesign} {
    flex-direction: column-reverse;
  }
`;

export const ResultsBox = styled(Box)`
  border: dashed 1px #addeff;
  border-radius: 0.72rem;
  width: 100%;
  margin-left: 0rem;
  margin-top: 2rem;
  background-color: #46aef4;

  display: flex;
  justify-content: center;

  align-items: center;

  ${mobileDesign} {
    min-height: 16rem;
    width: 80%;
    max-width: 50rem;
    min-width: 25rem;
    margin: 0 auto 5rem;
  }

  p {
    font-family: "Hellix", sans-serif;
    font-size: 2.46rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: #fff;
  }
`;

export const RewardsDisplay = styled(FlexBox)`
  position: relative;
  border-radius: 0.72rem;
  border: solid 1px #010a10;
  overflow: hidden;
  margin-right: 2.5rem;
  width: 36.6rem;

  ${mobileDesign} {
    width: 100%;
    margin: 0 0 7rem 0;
  }

  svg {
    display: block;
    background-color: #000;
    padding: 1rem;
    height: 6.5rem;
    width: 6.5rem;
    //margin: -1rem;
  }

  p {
    font-family: "F37 Judge";
    font-size: 3.63rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.27px;
    color: #010a10;
    padding: 1rem;
  }
`;

export const LoaderContainer = styled(Box)`
  position: relative;
  margin-right: 1rem;

  svg {
    display: block;
    margin: auto;
  }

  p {
    font-family: "F37 Judge", sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
    font-size: 2.2rem;
    font-weight: 600;
  }
`;

export const RewardsValue = styled(Stack)`
  background-color: #156da8;
  width: 100%;
  height: 100%;
  border-radius: 0.72rem;

  display: flex;
  justify-content: center;
  align-items: center;

  ${mobileDesign} {
    min-height: 16rem;
  }

  h5 {
    opacity: 0.65;
    font-family: "Hellix", sans-serif;
    font-size: 2.06rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    color: #fff;
    margin-bottom: 1rem;
  }

  p {
    font-size: 3.26rem;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.36px;
    color: #fff;
  }
`;
