import {Box, ListItem, styled, Typography} from "@mui/material";
import {mobileDesign} from "../../styles/breakpoints";
import {FlexBox} from "../../styles/layout";

export const StepsContainer = styled(Box)`
  border-radius: 1.4rem;
  background-color: #0f143f;

  padding: 8rem;
  
  ${mobileDesign}{
    border-radius: 0;
    margin: 0 -5.55%;
    padding: 5rem 7%;
  }
`;

export const StepItem = styled(ListItem)`
  padding-left: 0;
  p {
    color: #fff;
    opacity: 0.82;
    
    ${mobileDesign}{
      text-align: left;
    }
  }
`;

export const StepNumber = styled(Box)`
  display: flex;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1.2rem;

  width: 3rem;
  height: 3rem;
  background-color: #fff;
  border-radius: 50%;

  span {
    font-family: "Hellix-black", "Hellix", sans-serif;
    font-size: 2rem;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.21px;
    color: #000;
  }
`;

export const NoteContainer = styled(FlexBox)`

    ${mobileDesign}{
      svg{
        width: 4.6rem;
        height: 4rem;
      }
    }
`

export const StepsNote = styled(Typography)`
  opacity: 0.82;
  font-family: 'Hellix', sans-serif;
  font-size: 1.7rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: -0.18px;
  color: #fff;
`