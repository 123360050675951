import { Box, styled, Typography } from "@mui/material";
import { FlexBox } from "../../styles/layout";
import { largeMobileScreen, mobileScreen, tabletScreen } from "../../styles/breakpoints";

export const FooterContainer = styled(FlexBox)`
  background-color: #000000;
  width: 100vw;
  height: 8.14rem;
  color: rgba(255, 255, 255, 0.58);
  padding: 0 4.5rem;
  position: relative;
  margin-top: auto;

  ${tabletScreen} {
    flex-direction: column;
    justify-content: space-around;
    height: 12rem;
    padding: 5rem 0;
  }
  
  ${mobileScreen}{
    height: 15rem;
  }
`;

export const SubContainer = styled(FlexBox)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${tabletScreen} {
    flex-direction: column;
    //justify-content: center;
    //margin: 0 auto;
    width: 100vw;
  }
`;

export const LogoFooterBox = styled(Box)`
  height: 2rem;
  width: 10rem;

  ${tabletScreen} {
    display: none;
    margin-right: 3rem;
  }
  ${mobileScreen} {
    height: 2.5rem;
    margin-right: 2rem;
  }
  svg {
    opacity: 0.35;
    height: 2rem;
    width: 10rem;
  }
`;

export const AllRights = styled(Typography)`
  font-size: 1.51rem;
  letter-spacing: 0.94px;
  color: rgba(255, 255, 255, 0.58);
  flex-shrink: 0;
`;

export const SocialMediaList = styled(FlexBox)`
  column-gap: 2.6rem;

  ${largeMobileScreen} {
    padding: 0 2rem;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

export const SocialMediaContainer = styled(FlexBox)`
  svg {
    margin-right: 0.6rem;
  }

  p {
    font-family: "Hellix-Bold", "Hellix", sans-serif;
    font-size: 1.6rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 0.18px;
    color: #9da1aa;
  }

  &:hover {
    p,
    svg {
      opacity: 1;
    }
  }

  ${tabletScreen} {
    margin-bottom: 1.5rem;
  }
`;
