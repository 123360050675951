export function Logo(props) {
  return (
    <svg width="13rem" height="2.7rem" viewBox="0 0 130 27" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M109.26 64.585c1.436 0 2.6 1.167 2.6 2.606v.049a2.603 2.603 0 01-2.6 2.605H94.791a2.603 2.603 0 01-2.6-2.605v-.05a2.603 2.603 0 012.6-2.605h14.467zm24.06-21.54a8.007 8.007 0 015.917 13.4 8.007 8.007 0 01-5.918 13.4h-16.16a2.633 2.633 0 010-5.265h16.16a2.741 2.741 0 100-5.483h-4.87a2.633 2.633 0 01-2.632-2.632v-.04a2.633 2.633 0 012.633-2.632h4.87a2.741 2.741 0 100-5.482h-16.161a2.633 2.633 0 010-5.266h16.16zm-12.834 10.77a2.601 2.601 0 012.597 2.606v.049a2.601 2.601 0 01-2.597 2.605H94.788a2.601 2.601 0 01-2.597-2.605v-.05a2.601 2.601 0 012.597-2.605h25.698zm-11.229-10.77c1.437 0 2.6 1.167 2.6 2.606v.049a2.603 2.603 0 01-2.6 2.605H94.792a2.603 2.603 0 01-2.6-2.605v-.05a2.603 2.603 0 012.6-2.605h14.465zm-22.367 0a2.63 2.63 0 012.63 2.63v13.6c0 5.838-4.732 10.57-10.57 10.57-3.164 0-6.003-1.39-7.94-3.593a10.544 10.544 0 01-7.94 3.593c-5.838 0-10.57-4.732-10.57-10.57v-13.6a2.63 2.63 0 015.26 0v13.6a5.31 5.31 0 0010.62 0v-13.6a2.63 2.63 0 015.26 0v13.6a5.31 5.31 0 0010.62 0v-13.6a2.63 2.63 0 012.63-2.63zm91.509 26.8a2.63 2.63 0 002.63-2.63v-13.6c0-5.837-4.732-10.57-10.57-10.57-3.164 0-6.003 1.39-7.94 3.593a10.544 10.544 0 00-7.94-3.593c-5.838 0-10.57 4.733-10.57 10.57v13.6a2.63 2.63 0 105.26 0v-13.6a5.31 5.31 0 0110.62 0v13.6a2.63 2.63 0 105.26 0v-13.6a5.31 5.31 0 0110.62 0v13.6a2.63 2.63 0 002.63 2.63z"
        transform="translate(-52 -43)"
        fill="#FFF"
        fillRule="nonzero"
        stroke="none"
        strokeWidth={1}
      />
    </svg>
  );
}
